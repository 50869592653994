import utils from '@imt/vue-toolbox/src/utils';
import axios from 'axios';

export const mfaAxios = axios.create({
    baseURL: '/api/v1',
    headers: {
        'Content-Type': 'application/vnd.api+json',
    },
    withCredentials: true,
});

export default {
    namespaced: true,
    getters: {
        authenticatorAppSuggestions() {
            return {
                'Authy': 'https://authy.com/download/',
                'iPhone Passwords': 'https://support.apple.com/guide/iphone/automatically-fill-in-verification-codes-ipha6173c19f/ios',
                'Google Authenticator': 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
                'Microsoft Authenticator': 'https://www.microsoft.com/en-us/security/mobile-authenticator-app',
            };
        },
        contactUrl() {
            return 'https://www.imtins.com/contact/';
        },
        requestMethods() {
            return [
                {
                    text: 'Text Message',
                    value: 'sms',
                }, {
                    text: 'Phone Call',
                    value: 'call',
                }, {
                    text: 'Authenticator App Token',
                    value: 'code',
                }
            ];
        },
        selectedRequestMethod: (state) => {
            return state.requestMethod.length ? state.requestMethod[0] : '';
        },
    },
    actions: {
        async fetchUser({
            dispatch,
            commit,
        }) {
            let user = {};

            try {
                let response = await mfaAxios.get(`/user/`);

                user = utils.dataFormatter.deserialize(response.data);
            } catch (ex) {
                dispatch('twoFactorVerificationTimedOut', {
                    data: ex.response.data,
                    status: ex.response.status,
                    request: {
                        headers: ex.response.request.headers,
                    },
                });

                return false;
            }

            commit('SET_STATE_KEY', {
                key: 'user',
                value: user,
            });

            commit('SET_TWO_FACTOR_DURATION', {duration: user.twoFactorDuration});

            return user !== {};
        },
        async setupTwoFactorUser({
            dispatch,
            state,
        }) {
            try {
                let response = await mfaAxios.post(`/auth/?initialize=true`, utils.dataFormatter.serialize({
                    stuff: {
                        type: 'User',
                        phone_number: state.phoneNumber.replace(/[^0-9]/g,''),
                    },
                }));

                return response.data.data;
            } catch (ex) {
                if (ex.response.status === 403) {
                    let responseData = {
                        data: ex.response.data,
                        status: ex.response.status,
                        request: {
                            headers: ex.response.request.headers,
                        },
                    };

                    dispatch('twoFactorVerificationTimedOut', responseData);

                    return responseData;
                }

                throw ex;
            }
        },
        async verifyTwoFactorSetup({
            commit,
            dispatch,
            state,
        }, {code}) {
            try {
                let response = await mfaAxios.post(`/auth/?initial_verification=true`, utils.dataFormatter.serialize({
                    stuff: {
                        type: 'User',
                        code,
                        phone_number: state.phoneNumber.replace(/[^0-9]/g,''),
                    },
                }));

                commit('SET_STATE_KEY', {
                    key: 'user',
                    value: {
                        ...state.user,
                        twoFactorSetup: response.data.data.valid,
                    },
                });

                return response.data.data;
            } catch (ex) {
                let responseData = {
                    data: ex.response.data,
                    status: ex.response.status,
                    request: {
                        headers: ex.response.request.headers,
                    },
                };

                if (responseData.status === 403) {
                    dispatch('twoFactorVerificationTimedOut', responseData);

                    return responseData;
                }

                throw ex;
            }
        },
        twoFactorVerificationTimedOut({commit}, response) {
            if (response.status < 500) {
                commit(
                    'SET_LOGIN_ALERT',
                    'Your two-factor authentication timed out. Please log in and try again.',
                    {
                        root: true,
                    },
                );
            }
        },

        async requestVerificationCode({
            commit,
            state,
        }) {
            let response = await mfaAxios.post(`/auth/?verification_type=${state.requestMethod}`, {});

            commit('SET_STATE_KEY', {
                key: 'requestResponse',
                value: response.data,
            });

            commit('SET_STATE_KEY', {
                key: 'tokenRequested',
                value: true,
            });
        },
        async verifyCode({commit, state}) {
            let response = await mfaAxios.post(`/auth/`, utils.dataFormatter.serialize({
                stuff: {
                    type: 'User',
                    trust_device: state.trustDevice,
                    code: state.token,
                    channel: state.requestMethod[0],
                },
            }));

            commit('SET_STATE_KEY', {
                key: 'verificationResponse',
                value: response.data,
            });

            commit('SET_STATE_KEY', {
                key: 'valid',
                value: response.data.data.success === true,
            });

            return response.data.data;
        },
        cancelTwoFactor({commit, dispatch}) {
            commit('SET_QUERY', {});
            commit('SET_STATE_KEY', {
                key: 'twoFactorAuthToken',
                value: '',
            });

            dispatch('logout', undefined, {
                root: true,
            });
        },
        async fetchAuthenticatorAppSetupSecret() {
            const response = await mfaAxios.get('/auth/?manualAppSetup=true');

            return response.data;
        },
    },
    mutations: {
        SET_QUERY(state, params) {
            state.query = params;

            if (Object.keys(params).length) {
                localStorage.setItem('twoFactorQueryParams', JSON.stringify(params));
            } else {
                localStorage.removeItem('twoFactorQueryParams');
            }
        },
        SET_TWO_FACTOR_DURATION(state, {duration}) {
            state.twoFactorDuration = duration;
        },
        SET_STATE_KEY(state, {
            key,
            value,
        }) {
            state[key] = value;
        },
    },
    state: {
        phoneNumber: '',
        query: localStorage.getItem('twoFactorQueryParams') ?
            JSON.parse(localStorage.getItem('twoFactorQueryParams')) :
            {},
        requestMethod: [],
        requestResponse: {},
        twoFactorAuthToken: '',
        token: '',
        tokenRequested: false,
        trustDevice: false,
        user: {},
        valid: false,
        verificationResponse: {},
        twoFactorDuration: null,
    },
};
