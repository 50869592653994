<template>
    <div id="app">
        <RouterView
            :mode="mode"
            @toggle-mode="toggleMode($event)"
        />
    </div>
</template>

<script>
    import {modeMixin} from '@imt/vue-kit-car';

    export default {
        name: 'App',
        mixins: [
            modeMixin,
        ],
    };
</script>

<style lang="sass">
    @import "~@imt/vue-kit-car/src/sass/kit-car"
    @import "~@imt/vue-kit-car/src/sass/modules/multiselect"
</style>
