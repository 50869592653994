import {library} from '@fortawesome/fontawesome-svg-core';
import {faCaretUp} from '@fortawesome/pro-solid-svg-icons/faCaretUp';
import {faCheck} from '@fortawesome/pro-solid-svg-icons/faCheck';
import {faCog} from '@fortawesome/pro-solid-svg-icons/faCog';
import {faEye} from '@fortawesome/pro-solid-svg-icons/faEye';
import {faEyeSlash} from '@fortawesome/pro-solid-svg-icons/faEyeSlash';
import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import {faPencil} from '@fortawesome/pro-solid-svg-icons/faPencil';
import {faPlus} from '@fortawesome/pro-solid-svg-icons/faPlus';
import {faSearch} from '@fortawesome/pro-solid-svg-icons/faSearch';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons/faSpinner';
import {faTimes} from '@fortawesome/pro-solid-svg-icons/faTimes';
import {faTimesCircle} from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import {faTrashAlt} from '@fortawesome/pro-solid-svg-icons/faTrashAlt';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import Vue from 'vue';

library.add(
    faCaretUp,
    faCheck,
    faCog,
    faEye,
    faEyeSlash,
    faInfoCircle,
    faPencil,
    faPlus,
    faSearch,
    faSpinner,
    faTimes,
    faTimesCircle,
    faTrashAlt,
);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
