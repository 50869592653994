import {after, sameOrAfter} from '@imt/vue-toolbox/src/utils/rules';
import {
    ValidationProvider,
    ValidationObserver,
} from 'vee-validate';
import {extend} from 'vee-validate';
import {
    confirmed,
    email,
    max,
    min,
    regex,
    required,
    required_if as requiredIf,
} from 'vee-validate/dist/rules';
import Vue from 'vue';
import zxcvbn from 'zxcvbn';

export const passwordSpecialCharactersValidation = value => {
    return /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value);
};

export const passwordStrengthValidation = value => {
    return zxcvbn(value.length > 100 ? value.slice(0, 99) : value).score >= 3;
};

extend('confirmed', {
    ...confirmed,
    message: '{_field_} does not match.',
});

extend('email', email);
extend('max', max);
extend('min', {
    ...min,
    message: '{_field_} is too short.',
});

extend('regex', regex);
extend('required', {
    ...required,
    message: '{_field_} is required.',
});

extend('required_if', requiredIf);
extend('strong_password', {
    validate: passwordStrengthValidation,
    message: 'Password is too weak. Try something less guessable.',
});

extend('contains_number', {
    validate: value => {
        return /\d/.test(value);
    },
    message: 'Password must contain at least one number.'
});

extend('contains_special_character', {
    validate: passwordSpecialCharactersValidation,
    message: 'Password must contain at least one special character.'
});


extend('contains_lowercase', {
    validate: value => {
        return /[a-z]/.test(value);
    },
    message: 'Password must contain at least one lower case letter.'
});

extend('contains_uppercase', {
    validate: value => {
        return /[A-Z]/.test(value);
    },
    message: 'Password must contain at least one upper case letter.'
});

extend('after', after);
extend('sameOrAfter', sameOrAfter);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
