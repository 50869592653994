import Vue from 'vue';
import Router from 'vue-router';

import routes from '@/routes';
import {
    getters,
} from '@/store';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export function beforeEach(to, from, next) {
    let publicOnlyRouteNames = ['two-factor', 'create-account', 'forgot-password'],
        authRequiredRouteNames = ['admin'],
        authRequired = authRequiredRouteNames.some(r => to.name.indexOf(r) !== -1);

    if (!getters.getTokenFromCookie() && authRequired) {
        return next({
            name: 'login',
            query: {
                next: to.fullPath
            }
        });
    } else if (getters.getTokenFromCookie() && publicOnlyRouteNames.includes(to.name)) {
        return next({name: 'admin.users'});
    }

    next();
}

router.beforeEach((to, from, next) => beforeEach(to, from, next));

export default router;
