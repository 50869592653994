import utils from '@imt/vue-toolbox/src/utils';
import axios from 'axios';

import { passwordSpecialCharactersValidation, passwordStrengthValidation } from '@/plugins/vee-validate';

export const publicAxios = axios.create({
    baseURL: '/api/v1',
    headers: {
        'Content-Type': 'application/vnd.api+json',
    },
});

export default {
    namespaced: true,
    getters: {
        passwordRequirements() {
            return [
                {
                    cypressId: 'minlengthCriteria',
                    label: 'Have a minimum length of 8 characters',
                    isValid(value) {
                        return value.length >= 8;
                    },
                },
                {
                    cypressId: 'lowercaseCriteria',
                    label: 'Contain at least one lowercase letter',
                    isValid(value) {
                        return /[a-z]/.test(value);
                    },
                },
                {
                    cypressId: 'uppercaseCriteria',
                    label: 'Contain at least one uppercase letter',
                    isValid(value) {
                        return /[A-Z]/.test(value);
                    },
                },
                {
                    cypressId: 'numberCriteria',
                    label: 'Contain at least one number',
                    isValid(value) {
                        return /\d/.test(value);
                    },
                },
                {
                    cypressId: 'specialcharCriteria',
                    label: 'Contain at least one special character',
                    isValid(value) {
                        return passwordSpecialCharactersValidation(value);
                    },
                },
                {
                    cypressId: 'strengthCriteria',
                    label: 'Be a strong, non-guessable password',
                    isValid(value) {
                        return passwordStrengthValidation(value);
                    },
                },
            ];
        },
    },
    actions: {
        async fetchInvite({ commit }, byToken) {
            let response = await publicAxios.get(`/invitation/`, {
                headers: {
                    Authorization: byToken,
                },
            });

            commit('SET_STATE_KEY', {
                key: 'invite',
                value: utils.dataFormatter.deserialize(response.data),
            });

            return true;
        },
        async acceptInvite(_, { inviteToken, ...data }) {
            let response = await publicAxios.patch(
                '/invitation/',
                utils.dataFormatter.serialize({
                    stuff: {
                        type: 'User',
                        ...data,
                    },
                }),
                {
                    headers: {
                        Authorization: inviteToken,
                    },
                }
            );

            return {
                status: response.status,
                data: utils.dataFormatter.deserialize(response.data),
            };
        },
        async fetchUser({ commit }, byToken) {
            try {
                let response = await publicAxios.get(`/user/`, {
                    headers: {
                        Authorization: byToken,
                    },
                });

                commit('SET_STATE_KEY', {
                    key: 'tokenUser',
                    value: utils.dataFormatter.deserialize(response.data),
                });

                return true;
            } catch (ex) {
                return false;
            }
        },
    },
    mutations: {
        SET_STATE_KEY(state, { key, value }) {
            state[key] = value;
        },
    },
    state: {
        invite: {},
        tokenUser: {},
    },
};
