/* istanbul ignore file */
export default [
    {
        path: '/',
        children: [
            {
                path: '/',
                name: 'login',
                meta: {
                    title: 'Login',
                },
                component: () => import(/* webpackChunkName: "Login" */ '@/views/public/Login.vue'),
            },
            {
                path: '/verification',
                name: 'two-factor',
                meta: {
                    title: 'Two-Factor Authentication',
                },
                redirect: {name: 'two-factor.request'},
                component: () => import(/* webpackChunkName: "LoginTwoFactor" */ '@/views/public/LoginTwoFactor.vue'),
                children: [
                    {
                        path: 'setup',
                        name: 'two-factor.setup',
                        meta: {
                            title: 'Setup Two-Factor Authentication',
                        },
                        component: () => import(/* webpackChunkName: "LoginTwoFactorSetup" */ '@/views/public/LoginTwoFactorSetup.vue'),
                    },
                    {
                        path: 'request',
                        name: 'two-factor.request',
                        meta: {
                            title: 'Two-Factor Authentication',
                        },
                        component: () => import(/* webpackChunkName: "LoginTwoFactorRequest" */ '@/views/public/LoginTwoFactorRequest.vue'),
                    },
                    {
                        path: 'help',
                        name: 'two-factor.help',
                        meta: {
                            title: 'Two-Factor Authentication Help',
                        },
                        component: () => import(/* webpackChunkName: "LoginTwoFactorHelp" */ '@/views/public/LoginTwoFactorHelp.vue'),
                    },
                ],
            },
            {
                path: '/logout',
                name: 'logout',
                meta: {
                    title: 'Logging out...',
                },
                component: () => import(/* webpackChunkName: "Logout" */ '@/views/public/Logout.vue'),
            },
            {
                path: '/create-account',
                name: 'create-account',
                meta: {
                    title: 'Create Account',
                },
                component: () => import(/* webpackChunkName: "CreateAccount" */ '@/views/public/CreateAccount.vue'),
            },
            {
                path: '/forgot-password',
                name: 'forgot-password',
                meta: {
                    title: 'Forgot Password',
                },
                component: () => import(/* webpackChunkName: "ForgotPassword" */ '@/views/public/ForgotPassword.vue'),
            },
            {
                path: '/change-password',
                name: 'change-password',
                meta: {
                    title: 'Change Password',
                },
                component: () => import(/* webpackChunkName: "ChangePassword" */ '@/views/public/ChangePassword.vue'),
            },
            {
                path: '/change-email',
                name: 'change-email',
                meta: {
                    title: 'Change Email',
                },
                component: () => import(/* webpackChunkName: "ChangeEmail" */ '@/views/public/ChangeEmail.vue'),
            },
            {
                path: '/invitation',
                name: 'accept-invitation',
                meta: {
                    title: 'Activate Your Account | Welcome to IMT',
                },
                component: () => import(/* webpackChunkName: "UAcceptInvite" */ '@/views/public/UAcceptInvite.vue'),
            },
        ],
        component: () => import(/* webpackChunkName: "PublicBase" */ '@/views/public/Base.vue'),
    },
    {
        path: '/admin',
        children: [
            {
                path: '',
                name: 'admin',
                redirect: {
                    name: 'admin.users',
                },
            },
            {
                path: 'configurations',
                name: 'admin.configurations',
                meta: {
                    title: 'Application Configuration',
                },
                component: () => import(/* webpackChunkName: "Configurations" */ '@/views/admin/Configurations.vue'),
            },
            {
                path: 'users',
                name: 'admin.users',
                meta: {
                    title: 'Users',
                },
                component: () => import(/* webpackChunkName: "Users" */ '@/views/admin/Users.vue'),
                children: [
                    {
                        path: 'new',
                        name: 'admin.users.add',
                        meta: {
                            title: 'Users',
                        },
                        component: () => import(/* webpackChunkName: "UsersModal" */ '@/components/admin/UsersModal.vue'),
                    },
                    {
                        path: 'search',
                        name: 'admin.users.search',
                        meta: {
                            title: 'Users',
                        },
                        component: () => import(/* webpackChunkName: "UsersAdvancedSearchModal" */ './components/admin/UsersAdvancedSearchModal.vue'),
                    },
                    {
                        path: 'two-factor-configuration',
                        name: 'admin.users.two-factor-configuration',
                        meta: {
                            title: 'Users',
                        },
                        component: () => import(/* webpackChunkName: "UsersTwoFactorConfigurationModal" */ '@/components/admin/UsersTwoFactorConfigurationModal.vue'),
                    },
                    {
                        path: ':userId(\\d+)',
                        name: 'admin.users.edit',
                        meta: {
                            title: 'Users',
                        },
                        component: () => import(/* webpackChunkName: "UsersModal" */ '@/components/admin/UsersModal.vue'),
                    },
                ],
            },
            {
                path: 'groups',
                name: 'admin.groups',
                meta: {
                    title: 'Groups',
                },
                component: () => import(/* webpackChunkName: "Groups" */ '@/views/admin/Groups.vue'),
                children: [
                    {
                        path: 'new',
                        name: 'admin.groups.add',
                        meta: {
                            title: 'Groups',
                        },
                        component: () => import(/* webpackChunkName: "GroupsModal" */ '@/components/admin/GroupsModal.vue'),
                    },
                    {
                        path: ':groupId(\\d+)',
                        name: 'admin.groups.edit',
                        meta: {
                            title: 'Groups',
                        },
                        component: () => import(/* webpackChunkName: "GroupsModal" */ '@/components/admin/GroupsModal.vue'),
                    },
                ],
            },
            {
                path: 'permissions',
                name: 'admin.permissions',
                meta: {
                    title: 'Permissions',
                },
                component: () => import(/* webpackChunkName: "SystemsModal" */ '@/views/admin/Permissions.vue'),
                children: [
                    {
                        path: ':appLabel(.{0,100})',
                        name: 'admin.permissions.edit',
                        meta: {
                            title: 'Permissions',
                        },
                        component: () => import(/* webpackChunkName: "SystemsModal" */ '@/components/admin/SystemsModal.vue'),
                    },
                ],
            },
            {
                path: 'activity',
                name: 'admin.activity',
                meta: {
                    title: 'Activity',
                },
                component: () => import(/* webpackChunkName: "Activity" */ '@/views/admin/Activity.vue'),
            },
            {
                path: 'alerts',
                name: 'admin.alerts',
                meta: {
                    title: 'Alerts',
                },
                component: () => import(/* webpackChunkName: "Alerts" */ '@/views/admin/Alerts.vue'),
                children: [
                    {
                        path: 'new',
                        name: 'admin.alerts.add',
                        meta: {
                            title: 'Alerts',
                        },
                        component: () => import(/* webpackChunkName: "AlertsModal" */ '@/components/admin/AlertsModal.vue'),
                    },
                    {
                        path: ':alertId(\\d+)',
                        name: 'admin.alerts.edit',
                        meta: {
                            title: 'Alerts',
                        },
                        component: () => import(/* webpackChunkName: "AlertsModal" */ '@/components/admin/AlertsModal.vue'),
                    },
                ],
            },
            {
                path: '*',
                name: 'Not Found',
                meta: {
                    title: '404: Not Found',
                },
                component: () => import(/* webpackChunkName: "KCNotFound" */ '@imt/vue-kit-car/src/components/errors/NotFound.vue'),
            },
        ],
        component: () => import(/* webpackChunkName: "AdminBase" */ '@/views/admin/Base.vue'),
    },
    {
        path: '*',
        redirect: {
            name: 'login',
        },
    },
];
